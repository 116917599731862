const EtapasInventarioRiscoEnum = Object.freeze({
    LEVANTAMENTO_PERIGO_RISCO: 'LEVANTAMENTO_PERIGO_RISCO',
    PGR: 'PGR',
    AEP_AET: 'AEP_AET',
    LTCAT_LTIP: 'LTCAT_LTIP',
    PCMSO: 'PCMSO',
    CONCLUIR: 'CONCLUIR',
    VERSAO_CRIADA: 'VERSAO_CRIADA'
})

export const EtapasInventarioRiscoLabelEnum = new Map([
    [EtapasInventarioRiscoEnum.LEVANTAMENTO_PERIGO_RISCO, 'Levantamento Perigo/Fator de Risco'],
    [EtapasInventarioRiscoEnum.PGR, 'Inventário de risco'],
    [EtapasInventarioRiscoEnum.AEP_AET, 'Análise ergonômica'],
    [EtapasInventarioRiscoEnum.LTCAT_LTIP, 'Laudos'],
    [EtapasInventarioRiscoEnum.PCMSO, 'Procedimentos'],
    [EtapasInventarioRiscoEnum.CONCLUIR, 'Concluir']
]);

export const EtapaInventarioRiscoIconEnum = new Map([
    [EtapasInventarioRiscoEnum.LEVANTAMENTO_PERIGO_RISCO, 'pi pi-exclamation-triangle'],
    [EtapasInventarioRiscoEnum.PGR, 'pi pi-star-fill'],
    [EtapasInventarioRiscoEnum.AEP_AET, 'pi pi-box'],
    [EtapasInventarioRiscoEnum.LTCAT_LTIP, 'pi pi-flag-fill'],
    [EtapasInventarioRiscoEnum.PCMSO, 'pi pi-heart-fill'],
    [EtapasInventarioRiscoEnum.CONCLUIR, 'pi pi-check-circle']
]);

export const EtapasInventarioRiscoPathEnum = new Map([
    [EtapasInventarioRiscoEnum.LEVANTAMENTO_PERIGO_RISCO, '/aplicacao-levantamento'],
    [EtapasInventarioRiscoEnum.PGR, '/aplicacao-pgr'],
    [EtapasInventarioRiscoEnum.AEP_AET, '/aplicacao-aep'],
    [EtapasInventarioRiscoEnum.LTCAT_LTIP, '/aplicacao-laudo'],
    [EtapasInventarioRiscoEnum.PCMSO, '/aplicacao-pcmso'],
    [EtapasInventarioRiscoEnum.CONCLUIR, '/aplicacao-conclusao']
]);

export default EtapasInventarioRiscoEnum;
